import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state';
import api from '../tools/api';


export default createStore({
  state: {
    page: {
     mainTitle: '',
     subTitle: ''
   },
   innotoken: '',
   refresh_token : "",
   notification: 0,
   isAuth: false,
   role: "",
   fullname : "",
   myProjects:[],
   myLinksPlus: [],
   customerlogo: '',
   uid: null,
  },

  getters: {
     authenticated: (store) => store.isAuth,
     role: (store) => store.role,
     fullname: (store) => store.fullname,
     customerlogo: (store) => store.customerlogo,
     myProjects: (store) => store.myProjects,
     myLinksPlus: (store) => store.myLinksPlus,
      uid: (store) => store.uid
  },
  mutations: {
    UPDATE_PAGE_INFO(state, page){
      state.page = page;
    },
    UPDATE_ROLE(state, role){
      console.log("mutation");
      state.role = role;
         console.log(localStorage.getItem('innorole'));
       localStorage.setItem('innorole', role);
       console.log(localStorage.getItem('innorole'));
    },
    UPDATE_MYPROJECTS(state, project){
      state.myProjects = project;
    },
    UPDATE_MYLINKPLUS(state,project){
      state.myLinksPlus = project
    },
    UPDATE_PROJECTS(state, project){
      var objIndex = state.myProjects.findIndex((obj => obj._id.$oid == project._id.$oid));
      if(objIndex != -1){
          state.myProjects[objIndex] = project;
      }
      else{
          state.myProjects.push(project);
      }
    },
    UPDATE_LINKSPLUS(state, project){
      var objIndex = state.myLinksPlus.findIndex((obj => obj._id.$oid == project._id.$oid));
      if(objIndex != -1){
          state.myLinksPlus[objIndex] = project;
      }
      else{
          state.myLinksPlus.push(project);
      }
    },
    UPDATE_PROJECTS_REMOVE(state, project){
      var objIndex = state.myProjects.findIndex((obj => obj._id.$oid == project._id.$oid));
      if(objIndex != -1){
          state.myProjects.splice(objIndex, 1);
      }
    },
    UPDATE_LINKPLUS_REMOVE(state, project){
      var objIndex = state.myLinksPlus.findIndex((obj => obj._id.$oid == project));
      if(objIndex != -1){
          state.myLinksPlus.splice(objIndex, 1);
      }
    },
    UPDATE_NOTIFICATION(state,amount){
      console.log(amount);
      api.get("https://partnerplus.innopearl.ch/apidev/restrict/projects_tasks").then( (r) => {
        state.notification = (r.data) ? r.data.required : 0;
      }).catch( (e) => console.log(e));
    },
    LOGOSET (state,logoData) {
      if(logoData.$oid){
        state.customerlogo = logoData.$oid;
        localStorage.setItem('innologo', logoData.$oid);
      }
    },
    LOGIN (state,loginData) {
      console.log("loginData",loginData);
         state.innotoken = loginData.token.token;
         state.refresh_token = loginData.token.refresh_token;
         state.isAuth = true;
         state.role = loginData.role;
         state.fullname = loginData.fullname;
         state.uid = loginData.uid;
         if(loginData.logo){
           state.customerlogo = loginData.logo.$oid;
           localStorage.setItem('innologo', loginData.logo.$oid);
         }
         else{
            state.customerlogo = null;
            localStorage.setItem('innologo', null);
         }

         localStorage.setItem('innotoken', loginData.token.token);
         localStorage.setItem('refresh_token', loginData.token.refresh_token);
         localStorage.setItem('innorole', loginData.role);
              localStorage.setItem('innoname', loginData.fullname);
              localStorage.setItem('innouid', loginData.uid.$oid);
         api.get("https://partnerplus.innopearl.ch/apidev/restrict/projects").then( (r) => {
           state.myProjects = r.data;
           console.log("myProjects",r);
         }).catch( (e) => console.log(e));
         api.get("https://partnerplus.innopearl.ch/apidev/restrict/list_mylinkplus").then( (r) => {
           state.myLinksPlus = r.data;
           console.log("myLinksPlus",r);
         }).catch( (e) => console.log(e));
         api.get("https://partnerplus.innopearl.ch/apidev/restrict/projects_tasks").then( (r) => {
           state.notification = (r.data) ? r.data.required : 0;
         }).catch( (e) => console.log(e));
    },
    LOGOUT(state){
         state.refresh_token ="";
         state.innotoken ="";
         state.isAuth = false;
         state.role = "";
          state.fullname = "";
         state.notification = 0;
         state.customerlogo = null;
         state.uid = null;
         localStorage.removeItem('refresh_token');
         localStorage.removeItem('innotoken');
         localStorage.removeItem('innouid');
         localStorage.removeItem('innorole');
         localStorage.removeItem('innologo');
         localStorage.removeItem('currentBasicFilterSet');
         localStorage.removeItem('currentFilterSet');
         state.project = [];
    },
    INITIALISESTORE(state) {
         if(localStorage.getItem('innotoken')){
           state.innotoken = localStorage.getItem('innotoken');
           state.refresh_token = localStorage.getItem('refresh_token');
           state.role = localStorage.getItem('innorole');
            state.customerlogo = localStorage.getItem('innologo');
              state.fullname = localStorage.getItem('innoname');
              state.uid = localStorage.getItem('innouid');
           state.isAuth = true;
           api.get("https://partnerplus.innopearl.ch/apidev/restrict/projects").then( (r) => {
             state.myProjects = r.data;
             console.log(r.data);
             console.log(r);
           }).catch( (e) => console.log(e));

           api.get("https://partnerplus.innopearl.ch/apidev/restrict/list_mylinkplus").then( (r) => {
             state.myLinksPlus = r.data;
             console.log("myLinksPlus",r);
           }).catch( (e) => console.log(e));


           api.get("https://partnerplus.innopearl.ch/apidev/restrict/projects_tasks").then( (r) => {
             state.notification = (r.data) ? r.data.required : 0;
           }).catch( (e) => console.log(e));
          }
      }
  },
  actions: {
    updateRole(context, page){
      console.log("action");
      context.commit('UPDATE_ROLE',page);
    },
    updateProjects(context, pro){
      console.log("updateProjects");
      context.commit('UPDATE_MYPROJECTS',pro);
    },
    updateLinkplus(context, pro){
      console.log("updateProjects");
      context.commit('UPDATE_MYLINKPLUS',pro);
    },
    updatePage(context, page){
      context.commit('UPDATE_PAGE_INFO',page);
    },
    updateNotification({ commit }, payload) {
      return new Promise((resolve) => {
        // Effectuer les opérations nécessaires pour mettre à jour la notification
        // ...

        // Déclencher la mutation pour mettre à jour le store
        commit('UPDATE_NOTIFICATION', payload);
        resolve();
      });
    }
  },
  modules: {
  },
  plugins: [
    createMultiTabState(),
  ],
})
