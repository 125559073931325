<template lang="html">
  <div class="sidebar_menu" v-if="role == 'Admin'">
    <router-link to="/admin/my_projects" class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Projects</span>
    </router-link>
        <div class="projectListMenu">
          <div class="projectListItem">
            <router-link to="/admin/my_projects" class="submenu_link">
              <span class="small_menu_title">All projects</span>
            </router-link>
          </div>
    <div class="projectListItem pleft15" v-for="(project, p) in myProjects" :key="p">
      <router-link :to="'/admin/project/'+project._id.$oid" class="submenu_link" v-if="project._id"  :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'">
        <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
      </router-link>
    </div>

  </div>
    <div class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Administration</span>
    </div>
        <div class="projectListMenu">
          <div class="projectListItem">
            <router-link to="/admin/manage_users_admin" class="submenu_link">
              <span class="small_menu_title">Manage admins</span>
            </router-link>
          </div>
    <div class="projectListItem">
      <router-link to="/register_admin" class="submenu_link">
        <span class="small_menu_title">Add an expert</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <router-link to="/admin/all_projects" class="submenu_link">
        <span class="small_menu_title">Assign projects</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <span class="submenu_link">--------- </span>
    </div>
    <div class="projectListItem">
      <router-link to="/admin/manage_users" class="submenu_link">
        <span class="small_menu_title">Manage customers</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <router-link to="/register" class="submenu_link">
        <span class="small_menu_title">Add a customer</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <span class="submenu_link">--------- </span>
    </div>
    <div class="projectListItem">
      <router-link to="/admin/dict" class="submenu_link">
        <span class="small_menu_title">Manage taxonomies</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <span class="submenu_link">--------- </span>
    </div>
    <div class="projectListItem">
      <router-link to="/admin/all_startups" class="submenu_link">
        <span class="small_menu_title">Manage startups</span>
      </router-link>
    </div>
    <div class="projectListItem">
      <router-link to="/admin/partial_startups" class="submenu_link">
        <span class="small_menu_title">Profiles in progress</span>
      </router-link>
    </div>
  </div>

  <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
    <img :src="require('@/assets/header_my_account.svg')" alt=""/>
    <span class="username">{{fullname}}</span>
  </router-link>
  </div>
  <div class="sidebar_menu" v-else-if="role == 'Expert'">
    <router-link to="/expert/my_projects" class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Projects</span>
    </router-link>
      <div class="projectListMenu">
        <div class="projectListItem">
          <router-link to="/expert/my_projects" class="submenu_link">
            <span class="small_menu_title">All projects</span>
          </router-link>
        </div>
    <div class="projectListItem pleft15" v-for="(project, p) in myProjects" :key="p">
      <router-link :to="'/expert/project/'+project._id.$oid"  :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="project._id" >
        <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
      </router-link>
    </div>
  </div>

  <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
    <img :src="require('@/assets/header_my_account.svg')" alt=""/>
    <span class="username">{{fullname}}</span>
  </router-link>
  </div>
  <div class="sidebar_menu"  v-else>
    <div class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">My Partner+ Club</span>
    </div>
    <div class="projectListMenu">
      <div class="projectListItem">
        <router-link to="/startups_stats" class="submenu_link">
          <span class="small_menu_title">My dashboard</span>
        </router-link>
      </div>
      <div class="projectListItem">
        <router-link to="/startups_ecosystem" class="submenu_link">
          <span class="small_menu_title">My startup pool</span>
        </router-link>
      </div>
      <div class="projectListItem">
        <div to="/all_my_projects" class="submenu_link">
          <span class="small_menu_title">My projects</span>
        </div>
      </div>
      <div class="projectListItem pleft15" v-for="(project, p) in myProjects" :key="p">
        <router-link :to="'/projet/'+project._id.$oid" :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="project._id" >
          <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
        </router-link>
      </div>

        <router-link to="/selectYourChallenge" class="menu_link">
                <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
          <span class="small_menu_title">Create a new project</span>
        </router-link>

    </div>
    <router-link to="/list_filters" class="menu_link">
            <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="small_menu_title">My filters</span>
    </router-link>

    <router-link to="/list_link_plus" class="menu_link">
            <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="small_menu_title">Link+<sup>©</sup></span>
    </router-link>
      <div class="projectListMenu">
    <div class="projectListItem pleft15" >
      <router-link to="/link_plus" class="submenu_link" >
        <span class="small_menu_title">Create</span>
      </router-link>
    </div>
    <!-- <div class="projectListItem pleft15" v-for="(link, p) in myLinksPlus.slice(0,3)" :key="p">
      <router-link :to="'/manage_link_plus/'+link._id.$oid" :class="(link._id.$oid == activelink) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="link._id" >
        <span class="small_menu_title">{{link.name}}</span>
      </router-link>
    </div> -->
    <div class="projectListItem pleft15"  >
      <router-link to="/list_link_plus" class="submenu_link" >
        <span class="small_menu_title">View all</span>
      </router-link>
    </div>
  </div>
    <!-- <div class="projectListItem pleft15" v-for="(link, p) in myLinksPlus" :key="p">
      <router-link :to="'/manage_link_plus/'+link._id.$oid" :class="(link._id.$oid == activelink) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="link._id" >
        <span class="small_menu_title">{{link.name}}</span>
      </router-link>
    </div> -->


    <a href="https://www.innopearl.ch/contact/" class="menu_link" target="_blank" >
      <img :src="require('@/assets/menu_ask_us.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Ask us</span>
    </a>
    <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
      <img :src="'https://partnerplus.innopearl.ch/apidev/get_image/'+$store.state.customerlogo+'?token='+$store.state.innotoken" v-if="$store.state.customerlogo != null" alt="" class="logoImage">

      <img :src="require('@/assets/header_my_account.svg')" alt="" v-else/>
      <span class="username">{{fullname}}</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MenuSidebar',
  props: ['addAccount'],
  components: {
  },
  data() {
    return {
      activeProject:null,
      activelink:null,
      // menuItems: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My ecosystem',
      //     icon: require('@/assets/menu_my_ecosystem.svg'),
      //     isProjectContainer: true,
      //   },
      //   {
      //     href: 'https://www.innopearl.ch/contact/',
      //     title: 'Ask us',
      //     icon: require('@/assets/menu_ask_us.svg'),
      //     target: "ext"
      //   },
      // ],
      // menuItemsAdmin: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My ecosystem',
      //     icon: require('@/assets/menu_my_ecosystem.svg'),
      //     isProjectContainer: true,
      //   },
      //   {
      //     href: '/register_admin',
      //     title: 'Add an admin',
      //     icon: require('@/assets/menu_ask_us.svg'),
      //   },
      // ],
      // menuItemsPlus: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My account',
      //     icon: require('@/assets/header_my_account.svg'),
      //   },
      //
      // ],
    }
  },
  mounted(){
    if(this.$route.params.id ){
         this.activeProject = this.$route.params.id;
    }
    else if(this.$route.params.projectid){
         this.activeProject = this.$route.params.projectid;
    }
    else if(this.$route.params.pid){
      this.activeProject = this.$route.params.pid;
      this.activelink = this.$route.params.id;
    }
    else if(this.$route.params.lid ){
         this.activelink = this.$route.params.id;
    }
    else{
      this.activeProject = null;
    }
  },
  watch:{
   $route (to, from){
       console.log(to.params);
       if(to.params.id ){
            this.activeProject = to.params.id;
            this.activelink = this.$route.params.id;
       }
       else if(to.params.projectid){
            this.activeProject = to.params.projectid;
       }
       else if(to.params.pid){
         this.activeProject = to.params.pid;
       }
       else if(to.params.lid ){
            this.activelink = to.params.lid;
       }
       else{
         this.activeProject = null;
       }
       console.log(from);
   },
 },
    computed: mapState(['role',"fullname",'myProjects','myLinksPlus']),

}
</script>

<style lang="css" scoped>

.sidebar_menu{
  display: flex;
  flex-direction: column;
}

.menu_link{
  align-items: center;
  display: flex;
  gap: 20px;
  font-size: 15px;
  line-height: 19px;
letter-spacing: 0px;
color: #000000;
text-transform: uppercase;
text-decoration: none;
margin-top:10px
}
.menu_link:not(:first-child){
  margin-top:25px;
}
.menu_item{
  padding-bottom: 10px;
border-bottom: 1px solid #C2C4C6;
}

.projectListMenu{
  display:flex;
  flex-direction: column;
  gap: 15px;
  margin-top:10px;
  margin-bottom: 10px;
}
.submenu_link{
  display: flex;
  margin-left:60px;
  font-size: 15px;
  line-height: 19px;
letter-spacing: 0px;
color: #000000;
text-decoration: none;
}


.menu_icon_img{
  width: 40px;
}
.pleft15{
  padding-left:15px;
}

.pleft15 .submenu_link{
  padding-bottom:5px;
  border-bottom:1px solid #DDD;
}
.account_responsive{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size :10px;
  line-height: 12px ;
letter-spacing: 0px;
color: #3783C5;
text-transform: uppercase;
text-decoration: none;
margin-top:20px;
}
.account_responsive img{
width: 30px;
}
@media screen and (min-width:1200px){
  .account_responsive{
    display: none;
  }
}

.active_link_by_project,
.active_link_by_project *{
color:#3783C5 !important;
}
.logoImage{
  width: 50px !important;
  height:50px;
  object-fit:contain;
  border-radius: 50%;
}
</style>
