<template lang="html">
  <div class="topbar">
    <div id="innopearl_topbar">

      <div class="innopearl_topbar_content">
        <div class="pageTitle" v-if="$store.state.page.mainTitle">
            <span class="mainTitle">{{ $store.state.page.mainTitle }} </span>
            <span v-if="$store.state.page.subTitle" class="subTitle">{{$store.state.page.subTitle}}</span>
        </div>
        <!-- <router-link to="/all_my_projects" >
          <v-badge :content="$store.state.notification" color="error">
            <v-avatar size="30">
              <v-img :src="require('@/assets/header_cloche.svg')" alt=""></v-img>
            </v-avatar>
          </v-badge>
        </router-link> -->

        <router-link to="/expert/my_projects" v-if="role == 'Expert'" >
          <v-badge :content="$store.state.notification" color="error">
            <v-avatar size="30">
              <v-img :src="require('@/assets/header_cloche.svg')" alt=""></v-img>
            </v-avatar>
          </v-badge>
        </router-link>

        <router-link to="/admin/my_projects" v-else-if="role == 'Admin'">
          <v-badge :content="$store.state.notification" color="error">
            <v-avatar size="30">
              <v-img :src="require('@/assets/header_cloche.svg')" alt=""></v-img>
            </v-avatar>
          </v-badge>
        </router-link>

        <router-link to="/all_my_projects" v-else>
          <v-badge :content="$store.state.notification" color="error">
            <v-avatar size="30">
              <v-img :src="require('@/assets/header_cloche.svg')" alt=""></v-img>
            </v-avatar>
          </v-badge>
        </router-link>


        <router-link to="/myaccount" class="innopearl_topbar_link">
          <img :src="'https://partnerplus.innopearl.ch/apidev/get_image/'+$store.state.customerlogo+'?token='+$store.state.innotoken"  v-if="$store.state.customerlogo != null && $store.state.customerlogo != 'null'"  alt="" class="logoImage">

          <img :src="require('@/assets/header_my_account.svg')" alt="" v-else/>
          <span class="username">{{fullname}}</span>
        </router-link>
      </div>
    </div>

    <div id="topbar_responsive">
      <div class="row">
        <div class="col-2 afc">
          <div class="navbar" id="">
                  <div class="container nav-container">
                      <input class="checkbox" type="checkbox" name="" id="topbartoggle" @click='expand = !expand'  :checked="expand"/>
                      <label for="topbartoggle" class="hamburger-lines">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                      </label>
                    <div class="menu-items">
                      <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt="">
                      <div class="divider_blue_line"/>
                      <MenuSidebar addAccount='true'/>
                    </div>
                  </div>
                </div>
        </div>
        <div class="col-10 dflex">
          <div class="pageTitle" v-if="$store.state.page.mainTitle">
              <span class="mainTitle">{{ $store.state.page.mainTitle }} </span>
              <span v-if="$store.state.page.subTitle" class="subTitle">{{$store.state.page.subTitle}}</span>
          </div>
          <img  v-if="$store.state.page.mainTitle == ''" :src="require('@/assets/logo_innopearl.png')"  class="small_topbar_logo" alt="">
          <router-link to="/all_my_projects" class="notifs">
            <v-badge :content="$store.state.notification" color="error">
              <v-avatar size="30">
                <v-img :src="require('@/assets/header_cloche.svg')" alt=""></v-img>
              </v-avatar>
            </v-badge>
          </router-link>
          <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">

            <img :src="'https://partnerplus.innopearl.ch/apidev/get_image/'+$store.state.customerlogo+'?token='+$store.state.innotoken" v-if="$store.state.customerlogo != null && $store.state.customerlogo != 'null'" alt="" class="logoImage">

            <img :src="require('@/assets/header_my_account.svg')" alt="" v-else/>
            <span class="username">{{fullname}}</span>
          </router-link>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import MenuSidebar from '@/components/sidebar/MenuSidebar.vue';
import { mapState } from 'vuex';
export default {
  name: 'TopBar',
  // props: ['mainTitle','subTitle'],
  components: {
    MenuSidebar
  },
  data() {
    return {
      notif_amount : 0,
      expand: false,
    }
  },
  watch: {
$route(to, from) {
  console.log(to);
  console.log(from);
  console.log(this.expand);
  this.expand = false
}
},
 computed: mapState(['role','fullname','myProjects','customerlogo']),
}
</script>
<style media="screen" scoped>
#innopearl_topbar,
#topbar_responsive {
  padding: 20px;
  position: sticky;
top: 0;
background: #FFF;
z-index: 30;
}

.innopearl_topbar_content{
  display:flex;
  justify-content: flex-end;
  gap: 20px;
}
.innopearl_topbar_link{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size :10px;
  line-height: 12px ;
letter-spacing: 0px;
color: #3783C5;
text-transform: uppercase;
text-decoration: none;
}
.innopearl_topbar_link img{
width: 30px;
}


.pageTitle{
  flex-grow: 1;
}
.mainTitle{
  text-align: left;
  font-size: 24px;
  line-height: 29px;
letter-spacing: 0px;
color: #3783C5;
opacity: 1;
display:inline-block;
}

.subTitle{
  text-align: left;
  font-size: 21px;
  line-height: 25px;
letter-spacing: 0px;
color: #B38D2F;
opacity: 1;
margin-left:10px;
display:inline-block;
}



#topbar_responsive{
  position:fixed;
  width:100%;
}
#topbar_responsive  .navbar {
    width: 100%;
  }

#topbar_responsive   .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

#topbar_responsive   .navbar .menu-items {
    display: flex;
    position: fixed;
background: #FFF;
top: 0;
border: 0;
bottom: 0;
left: 0;
right: 0;

  }

#topbar_responsive   .navbar .nav-container li {
    list-style: none;
  }

#topbar_responsive   .navbar .nav-container a {
    text-decoration: none;
    /* color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem; */
  }


#topbar_responsive   .nav-container {
    display: block;
    position: relative;
    height: auto;
  }

#topbar_responsive   .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 1px;
    width: 1px;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

#topbar_responsive   .nav-container .hamburger-lines {
    display: block;
    height: 16px;
    width: 22px;
    position: relative;
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
      cursor: pointer;
      z-index:500;
  }

#topbar_responsive   .nav-container .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 20px;
    border-radius: 10px;
    background: #0e2431;
  }

#topbar_responsive   .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

#topbar_responsive   .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

#topbar_responsive   .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

#topbar_responsive   .navbar .menu-items {
    padding: 20px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    font-size:12px;
  }



#topbar_responsive   .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

#topbar_responsive   .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

#topbar_responsive   .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

#topbar_responsive   .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }



@media screen and (max-width: 1201px){
  #innopearl_topbar{
    display:none;
  }
}
@media screen and (max-width: 1200px){
  .pageTitle{
    display:flex;
    flex-direction: column;
  }
  .pageTitle .subTitle{
      margin-left: 0px;
  }

}
@media screen and (min-width: 1200px){
  #topbar_responsive{
    display:none;
  }
}

#topbar_responsive .sidebar_logo{
  max-width: 100%;
  width:150px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 20px;
}

#topbar_responsive .dflex{
  display: flex;
}
#topbar_responsive .notifs{
  margin-right:10px;
}
@media screen and (max-width: 1200px){
#topbar_responsive .small_topbar_logo{
  width:100px;
margin:auto;
}
a.notifs,
div.afc{
  align-self: center;
}
.account_responsive{
  margin-left:20px;
}
}

@media screen and (max-width: 690px){
  .account_responsive{
    display:none;
  }
}
.logoImage{
  width: 100px !important;
  height:40px;
  object-fit:contain;
}
</style>
